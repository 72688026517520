import React, { ReactElement } from "react"
import LendingPolicyContainer from "../containers/lending-policy"
import DefaultLayout from "../layouts/default"

interface Props { }

function PrivacyPage({ }: Props): ReactElement {
  return (
    <div>
      <DefaultLayout title="Lending Policy">
        <LendingPolicyContainer />
      </DefaultLayout>
    </div>
  )
}

export default PrivacyPage
